import { environment } from './../../../environments/environment';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IconComponent } from '../../icon/icon.component';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'MenuLink',
  standalone: true,
  imports: [CommonModule, IconComponent, RouterModule],
  templateUrl: './link.component.html',
})
export class LinkComponent implements OnInit {

  env = environment.mode;

  @Input() label!:string;
  @Input() link!:string;
  @Input() disabled = false;
  @Input() icon!:string;

  classDefault = 'hover:bg-gray-800 hover:text-white rounded-lg p-1 px-3 mx-1 transition-all disabled:opacity-40 transition-all flex items-center justify-cnter'

  active = "bg-blue-900 text-white"

  ngOnInit(): void {
   this.disabled = (this.env == 'development') ? false : this.disabled;
  }

}
