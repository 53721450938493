import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector,
} from '@angular/core';
import { SidebarComponent } from './sidebar.component';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {

  private componentRef!: ComponentRef<any>;

  originalBodyOverflow!: any;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  emitOpen() {
    this.render();
  }

  emitClose() {
    this.destroy();
  }

  private render() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SidebarComponent);
    this.componentRef = componentFactory.create(this.injector);
    this.appRef.attachView(this.componentRef.hostView);
    const domElem = (this.componentRef.hostView as any).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
    domElem.focus();

    domElem.style.position = 'fixed';
    domElem.style.zIndex = '9000';
    domElem.style.left = '0';
    domElem.style.top = '0';
    domElem.style.height = '100vh';
    domElem.style.width = '100vh';

    this.originalBodyOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
  }

  private destroy() {
    if (this.componentRef) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      document.body.style.overflow = this.originalBodyOverflow;
    }
  }
}
