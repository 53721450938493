import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../modules/auth/auth.service';

@Pipe({
  name: 'branch',
  standalone: true
})
export class BranchPipe implements PipeTransform {

  constructor(private authService: AuthService) {}

  transform(field: string): unknown {
    const branch = this.authService.branch;
    if (branch && field in branch) {
      return branch[field];
    }
    return null;
  }

}
