<button (click)="closeSidebar()" [disabled]="disabled" *ngIf="link; else noLink" type="button" [class]="classDefault"  [routerLink]="[link]" [routerLinkActive]="active" [routerLinkActiveOptions]="{exact:true}">
  <div class="flex items-center justify-center">
    <Icon [src]="icon"/>
    <span *ngIf="label" class="block ms-2">{{label}}</span>
  </div>
</button>

<ng-template #noLink>
  <button (click)="closeSidebar()" [disabled]="disabled" type="button" [class]="classDefault">
    <div class="flex items-center justify-center">
      <Icon [src]="icon"/>
      <span *ngIf="label" class="block ms-2">{{label}}</span>
    </div>
  </button>
</ng-template>
