import { Pipe, PipeTransform, inject } from '@angular/core';
import { AuthService } from '../modules/auth/auth.service';

@Pipe({
  name: 'me',
  standalone: true
})
export class MePipe implements PipeTransform {

  constructor(private authService: AuthService) {}

  transform(field: string): unknown {
    const me = this.authService.me;
    if (me && field in me) {
      return me[field];
    }
    return null;
  }

}
