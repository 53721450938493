<div
  class="z-50 fixed bottom-0 left-0 w-11/12 md:w-72 h-screen p-4 overflow-y-auto transition-transform duration-300 bg-white dark:bg-gray-800 transform -translate-x-full"
  [class.translate-x-0]="isOpen" [class.-translate-x-full]="!isOpen">

  <div class="absolute left-5 max-h-12">
    <img src="./assets/images/logo/logo_inline_color.svg" width="150px">
  </div>

  <button (click)="close()" type="button"
    class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xl absolute end-2.5 flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white h-8 w-8">
    <Icon src="close" class="mt-1" />
    <span class="sr-only">Close menu</span>
  </button>

  <div class="py-2 pt-12 overflow-y-auto">

    <div class="flex justify-start items-center flex-col mb-5">

      <div class="flex items-center justify-between w-full">

        <div class="flex flex-col justify-start w-1/2 my-5 ms-3">
          <small class="truncate ... flex items-center flex-row font-bold">
            <div class="w-5 h-4 block rounded-md me-2" style.background="{{'color_cod' | branch}}"></div>
            {{ 'name' | branch}}
          </small>

          <div class="text-sm truncate ...  font-bold text-econo-primary-700 flex items-center">
            <Icon src="users" class="text-lg me-2"></Icon>
            {{ 'name' | me}}
          </div>
        </div>

      </div>

      <div class="flex flex-col w-full justify-center">

        <button (click)="close()" [routerLink]="['/auth/logout']" [class]="classLinks">
          <i class="icon-door-exit"><span class="path1"></span><span class="path2"></span></i> Sair
        </button>

        <div class="space-y-2 font-medium">

          <hr class="mb-3 my-2" />

          <SidebarLink (clicked)="close()" icon="calendar" label="Reservas" link="/reservas" />
          <SidebarLink (clicked)="close()" icon="barrier" label="Entrada" link="/entrada" />
          <SidebarLink (clicked)="close()" icon="checklist" label="Checklists" link="/checklists" />
          <SidebarLink (clicked)="close()" icon="car-pending" label="Pendentes" link="/patio/pendentes" />
          <SidebarLink (clicked)="close()" icon="patio" label="Pátio" link="/patio" />
          <SidebarLink (clicked)="close()" icon="wallet" label="Caixa" link="/caixa" />
          <SidebarLink (clicked)="close()" icon="conference" label="Conferências" link="/conferencias" />
          <SidebarLink (clicked)="close()" icon="reports" label="Relatórios" link="/relatorios" [disabled]="true" />

          <span *HasRole="['ADMIN']">
            <button type="button" (click)="openMenu('admin')"
              class="disabled:opacity-40 flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-t-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
              [ngClass]="{'bg-blue-900 text-white hover:bg-gray-300':menus.admin.open}">
              <Icon src="admin" class="me-1" />
              <span class="flex-1 text-left rtl:text-right whitespace-nowrap">Administração</span>
              <Icon [src]="!menus.admin.open ? 'arrow-down' : 'arrow-up'" />
            </button>
            <ul class="p-2 space-y-2 bg-gray-300 rounded-b-lg" [ngClass]="{'hidden':!menus.admin.open}">
              <li>
                <SidebarLink (clicked)="close()" icon="users" label="Usuários" link="/administracao/usuarios" />
              </li>
              <li>
                <SidebarLink (clicked)="close()" icon="car-sided-left" label="Veículos"
                  link="/administracao/veiculos" />
              </li>
              <li>
                <SidebarLink (clicked)="close()" icon="vehicle-model" label="Modelos" link="/administracao/modelos" />
              </li>
              <li>
                <SidebarLink (clicked)="close()" icon="agreement" label="Contratos" link="/administracao/contratos" />
              </li>
              <li>
                <SidebarLink (clicked)="close()"  icon="customer" label="Clientes"
                  link="/administracao/clientes" />
              </li>
              <li>
                <SidebarLink (clicked)="close()"  icon="buildings" label="Unidades"
                  link="/administracao/unidades" />
              </li>
              <li>
                <SidebarLink (clicked)="close()" icon="barrier" label="Entradas"
                  link="/administracao/entradas" />
              </li>
              <li>
                <SidebarLink (clicked)="close()" [disabled]="true" icon="wallet" label="Caixas" link="/administracao/caixas" />
              </li>
              <li>
                <SidebarLink (clicked)="close()" [disabled]="true" icon="moneys" label="Despesas e Receitas"
                  link="/administracao/despesas-receitas" />
              </li>
              <li>
                <SidebarLink (clicked)="close()" [disabled]="true" icon="mensalista" label="Mensalistas"
                  link="/administracao/mensalistas" />
              </li>
              <li>
                <SidebarLink (clicked)="close()" [disabled]="true" icon="patio" label="Vagas"
                  link="/administracao/vagas" />
              </li>

            </ul>
          </span>

          <hr class="mb-3 my-2" />

          <SidebarLink (clicked)="close()" icon="school" label="Tutoriais" link="/tutoriais" />

          <SidebarLink (clicked)="close()" icon="code" label="Litwo" (click)="goToLitwo()" />

          <div class="opacity-50 dark:opacity-25 mx-3 border-t border-gray-500 text-sm">
            Versão: {{versionService.get}}
          </div>


        </div>


      </div>

    </div>

  </div>

</div>

<div (click)="close()" class="absolute left-0 top-0 w-screen h-screen bg-slate-800 bg-opacity-40 z-40"></div>
