import { AuthService } from './../modules/auth/auth.service';
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[HasRole]',
  standalone: true
})
export class HasRoleDirective {


  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private authService:AuthService) {}

  @Input() set HasRole(roles: string[]) {
    if (this.checkPermission(roles)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission(allowedRoles: string[]): boolean {
    return this.authService.roles.some((role:any) => allowedRoles.includes(role));
  }

}
