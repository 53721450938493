<button [disabled]="disabled" *ngIf="link; else noLink" type="button" [class]="classDefault"  [routerLink]="[link]" [routerLinkActive]="active" [routerLinkActiveOptions]="{exact:true}">
  <div class="flex items-center justify-center">
    <Icon class="text-3xl lg:text-xl" [src]="icon" [ngClass]="{'me-0 lg:me-2':label}"/>
    <div *ngIf="label" class="hidden lg:block">{{label}}</div>
  </div>
</button>

<ng-template #noLink>
  <button [disabled]="disabled"  type="button" [class]="classDefault">
    <div class="flex items-center justify-center">
      <Icon class="text-3xl lg:text-xl" [src]="icon" [ngClass]="{'me-0 lg:me-2':label}"/>
      <div *ngIf="label" class="hidden lg:block">{{label}}</div>
    </div>
  </button>
</ng-template>
