import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'Titler',
  standalone: true,
  imports: [
    CommonModule, IconComponent
  ],
  templateUrl: './titler.component.html',
})
export class TitlerComponent implements OnInit {
  @Input({required:true}) title!:string;
  @Input() subtitle!:string;
  @Input() icon!:any;
  @Input() size!:string;

  sized:string = 'text-xl'

  ngOnInit(): void {
    this.setSize();
  }

  setSize(){
    switch(this.size){
      case 'xs': this.sized = 'text-xs'; break;
      case 'sm': this.sized = 'text-sm'; break;
      case 'md': this.sized = ''; break;
      case 'xl': this.sized = 'text-xl'; break;
      case '2xl': this.sized = 'text-2xl'; break;
      case '3xl': this.sized = 'text-3xl'; break;
    }
  }
}
